import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { graphql } from "gatsby";
import PageLayout from "./../../../templates/service";
export const PageQuery_service_nl_backendmdx = graphql`
        query PageQuery_service_nl_backendmdx {
            # Required by gatsby-plugin-react-i18next
            locales: allLocale(filter: {language: {eq: "nl"}, ns: {in: ["common", "service"]}}) {
                ...TranslationFragment
            }
            # Required by gatsby-plugin-image
            mdx(frontmatter:
                {
                    path: {regex: "/backend/"},
                    language: {eq: "nl"}
                }) {
                frontmatter {
                    ...MdxServiceFrontmatterFragment
                }
            }
        }
    `;
export const _frontmatter = {
  "title": "Back-end",
  "path": "/service/backend",
  "date": "2022-10-15T00:00:00.000Z",
  "template": "Service",
  "language": "nl",
  "icon": "bx-cloud",
  "tags": ["engineer", "architectuur", "senior"],
  "experience": [{
    "title": "Streaming: Gewrichten",
    "task": "Ontwikkelaar",
    "tags": ["lead", "ontwikkelaar"],
    "outcome": ["Sneller dan video", "Fundering opgezet voor streams", "Gegevensintensief werk mogelijk gemaakt"],
    "link": ["https://www.fifa.com/technical/football-technology/news/limb-tracking-technology-offers-new-array-of-possibilities", "https://www.fifa.com/technical/football-technology/news/semi-automated-offside-tech-aids-referees-and-fans-at-fifa-club-world-cup-tm"],
    "image": ["./images/backend/bs-fifa-stream-limb-01.jpg"],
    "copyright": "Beyond Sports B.V."
  }, {
    "title": "Video & Audio Synchronisatie",
    "task": "Technische Ondersteuning / QA",
    "tags": ["ontwikkelaar", "qa"],
    "outcome": ["Geautomatiseerde synchronisatie", "Shift & conversie via AI-model", "174 gegenereerde video's"],
    "link": ["https://www.youtube.com/playlist/?list=PLIqZSskBjtdstFEHiK0ioq3NZMLcFMy_6"],
    "image": ["./images/backend/nmt-ns-julia-01.jpg"],
    "copyright": "Nick & Simon B.V."
  }, {
    "title": "Streaming: Positionele Data",
    "task": "Senior Engineer",
    "tags": ["senior", "engineer", "ontwikkelaar"],
    "outcome": ["Kostenbesparing", "Gebruikt in TV-uitzendingen", "Vervangbare databronnen + aanpassingen"],
    "link": ["https://www.beyondsports.nl/solutions", "https://www.youtube.com/watch?v=LRDmbMPKzRE"],
    "image": ["./images/backend/bs-sky-mnf-01.jpg"],
    "copyright": "Beyond Sports B.V. / Sky Group Ltd"
  }, {
    "title": "Compositie Videobeelden",
    "task": "Ontwikkelaar Pipeline / QA",
    "tags": ["ontwikkelaar", "qa"],
    "outcome": ["Back-end + desktop UI", "Sterk verbeterde doorlooptijd", "Gebruikt voor diverse TV-shows", "Compositiepijplijn voor videobeelden"],
    "link": ["https://www.npostart.nl/tumblies/KN_1713957", "https://vimeo.com/49843036"],
    "image": ["./images/backend/nmt-kro-tumblies-01.jpg"],
    "copyright": "il Luster"
  }, {
    "title": "Streaming: Multikanaal",
    "task": "Senior Engineer",
    "tags": ["senior", "engineer", "ontwikkelaar"],
    "outcome": ["Kostenbesparing", "Synchronisatie tussen streams", "Uitsturen van diverse streams tegelijk"],
    "link": ["https://www.beyondsports.nl/solutions"],
    "image": ["./images/backend/bs-nhl-stream-multi-01.jpg"],
    "copyright": "Beyond Sports B.V."
  }, {
    "title": "Crypto Tracking",
    "task": "Ontwikkelaar",
    "tags": ["ontwikkelaar"],
    "outcome": ["Conversie tussen financiële middelen", "Verbeterd inzicht in handelstarieven"],
    "link": ["https://github.com/Cheesebyte/cheesebyte-holdmycrypto"],
    "image": ["./images/backend/cb-holdmycrypto-01.png"],
    "copyright": "Cheesebyte"
  }]
};
const layoutProps = {
  PageQuery_service_nl_backendmdx,
  _frontmatter
};
const MDXLayout = PageLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "stabiel-back-end",
      "style": {
        "position": "relative"
      }
    }}>{`Stabiel back-end`}<a parentName="h1" {...{
        "href": "#stabiel-back-end",
        "aria-label": "stabiel back end permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h1>
    <p>{`Als back-end ontwikkelaar heb ik het voortouw genomen bij enkele streamingprojecten. Mensen waren enthousiast over de prestaties in de praktijk.`}</p>
    <blockquote>
      <p parentName="blockquote">{`De snelheid waarmee gegevens worden doorgevoerd stelt `}{`[bedrijf]`}{` in staat hele wedstrijden in real-time uit te zenden ...`}</p>
      <p parentName="blockquote">{`In feite bouwt men soms een vertraging in, om de visualisatie niet eerder te tonen dan de actuele televisiebeelden.`}</p>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "https://blog.infront.sport/player-positional-tracking-experiences"
        }}>{`Bronartikel`}</a></p>
    </blockquote>
    <h2 {...{
      "id": "vaardigheden",
      "style": {
        "position": "relative"
      }
    }}>{`Vaardigheden`}<a parentName="h2" {...{
        "href": "#vaardigheden",
        "aria-label": "vaardigheden permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h2>
    <ul>
      <li parentName="ul">{`10+ jaar back-end ervaring`}</li>
      <li parentName="ul">{`Ervaren in het produceren van onderhoudbare`}</li>
      <li parentName="ul">{`Ervaren in het rekening houden met veiligheid`}</li>
      <li parentName="ul">{`Ervaren in het opzetten van snelle + schaalbare code`}</li>
    </ul>
    <h2 {...{
      "id": "technisch",
      "style": {
        "position": "relative"
      }
    }}>{`Technisch`}<a parentName="h2" {...{
        "href": "#technisch",
        "aria-label": "technisch permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h2>
    <ul>
      <li parentName="ul">{`Ik gebruik `}<a parentName="li" {...{
          "href": "https://nl.wikipedia.org/wiki/SOLID"
        }}>{`SOLID`}</a>{` als basis`}</li>
      <li parentName="ul">{`Ik volg `}<a parentName="li" {...{
          "href": "https://owasp.org/www-project-top-ten/"
        }}>{`OWASP`}</a>{` richtlijnen`}</li>
      <li parentName="ul">{`Microservices, containerisatie`}</li>
      <li parentName="ul">{`.NET (Framework, Core, 5+), Node.js, ASP.NET`}</li>
      <li parentName="ul">{`Bereid tot gebruik van Azure, AWS en andere platformen`}</li>
    </ul>





    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      